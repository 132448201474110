import { has, get } from 'lodash';
import { apiUrl } from '../utils/const';
import { getUser, httpClient } from '../utils/tools';
import queryString from 'query-string';

interface Query {
  page?: number;
  limit?: number;
  sort?: string;
  order?: string;
  filter?: any;
  search?: string;
}

export interface DeleteResponse {
  message: string;
}

export const composeError = (errorBody: any) => {
  const errors: string[] = Object.values(get(errorBody, 'errors', {}));
  const message = get(errorBody, 'message', []);
  let msg = `${message}. \n`;
  errors.map((val: string) => (msg = msg + val + '\n'));
  return msg;
};

export default {
  getList: (resource: any, params: any) => {
    let query: Record<any, any> = {};

    if (has(params, 'pagination')) {
      const { page, perPage } = params.pagination;
      query['$skip'] = (page - 1) * perPage;
      query['$limit'] = perPage;

      if (resource === 'countries') {
        query['$limit'] = 999;
      }
    }

    if (has(params, 'sort')) {
      const { field, order } = params.sort;
      query[`$sort[${field}]`] = order === 'ASC' ? 1 : -1;
    }

    if (has(params, 'filter')) {
      for (const property in params.filter) {
        query[property] = params.filter[property];
      }

      // users
      if (resource === 'users') {
        if (has(params.filter, 'q')) {
          query['$or[0][firstName][$like]'] = `${params.filter.q}%`;
          query['$or[1][lastName][$like]'] = `${params.filter.q}%`;
        }

        if (has(params.filter, 'email')) {
          query['email[$like]'] = `%${params.filter.email}%`;
          delete query['email'];
        }
      }

      if (resource === 'pages') {
        if (has(params.filter, 'name')) {
          query['name[$like]'] = `%${params.filter.name}%`;
          delete query['name'];
        }
      }

      // page-checkers
      if (resource === 'page-checkers') {
        if (has(params.filter, 'url')) {
          delete query['url'];
          query['url[$like]'] = `%${params.filter.url}%`;
        }

        if (has(params.filter, 'name')) {
          delete query['name'];
          query['name[$like]'] = `%${params.filter.name}%`;
        }
      }

      // form-checkers
      if (resource === 'form-checkers') {
        if (has(params.filter, 'url')) {
          delete query['url'];
          query['url[$like]'] = `%${params.filter.url}%`;
        }

        if (has(params.filter, 'name')) {
          delete query['name'];
          query['name[$like]'] = `%${params.filter.name}%`;
        }
      }

      // Always delete q
      delete query['q'];
    }

    let url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url, getUser()).then(({ json }: any) => ({
      data:
        resource === 'countries'
          ? json.data.map((resource: any) => ({
              ...resource,
              id: resource.code,
            }))
          : json.data,
      total: json.total,
    }));
  },

  getOne: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, getUser()).then(
      ({ json }: any) => ({
        data: json,
      }),
    ),

  getMany: (resource: any, params: any) => {
    let idString = '';

    if (has(params, 'ids')) {
      if (params.ids.length > 1) {
        for (const id of params.ids) {
          idString += `id[$in]=${id}&`;
        }

        idString = idString.slice(0, -1);
      } else {
        idString = `id=${params.ids[0]}`;
      }
    }

    const url = `${apiUrl}/${resource}?${idString}&$limit=500`;

    return httpClient(url, getUser()).then(({ json }: any) => ({
      data: json.data,
    }));
  },

  getManyReference: (resource: any, params: any) => {
    let query: Record<any, any> = {};

    if (has(params, 'pagination')) {
      const { page, perPage } = params.pagination;
      query['$skip'] = (page - 1) * perPage;
      query['$limit'] = perPage;
    }

    if (has(params, 'sort')) {
      const { field, order } = params.sort;
      query[`$sort[${field}]`] = order === 'ASC' ? 1 : -1;
    }

    if (has(params, 'filter')) {
      for (const property in params.filter) {
        query[property] = params.filter[property];
      }
    }

    if (has(params, 'id') && has(params, 'target')) {
      query[params.target] = params.id;
    }

    const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url, getUser()).then(({ headers, json }: any) => ({
      data: json.data,
      total: json.total,
    }));
  },

  update: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getUser(),
    })
      .then(({ json }: any) => ({ data: json }))
      .catch((error: any) => {
        const body = get(error, 'body', {});
        const msg = composeError(body);
        return Promise.reject({ message: msg });
      }),

  updateMany: (resource: any, params: any) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      ...getUser(),
    }).then(({ json }: any) => ({ data: json }));
  },

  create: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
      ...getUser(),
    })
      .then(({ json }: any) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((error: any) => {
        const body = get(error, 'body', {});
        const msg = composeError(body);
        return Promise.reject({ message: msg });
      }),

  delete: (resource: any, params: any) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
      ...getUser(),
    }).then(({ json }: any) => ({ data: json })),

  deleteMany: (resource: any, params: any) => {
    const ids = params.ids;
    const deleteResource = (id: number) =>
      httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'DELETE',
        ...getUser(),
      })
        .then(({ body }: any) => JSON.parse(body))
        .then(({ message }: DeleteResponse) =>
          message === 'Success' ? id : null,
        );

    return Promise.all(ids.map(deleteResource)).then((ids: any) => {
      return {
        data: ids,
      };
    });
  },
};
