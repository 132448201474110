import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Admin, Resource } from 'react-admin';
import reportWebVitals from './reportWebVitals';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import LoginPage from './pages/authentication/LoginPage';
import customers from './pages/customers';
import plans from './pages/plans';
import pageCheckers from './pages/pageCheckers';
import formCheckers from './pages/formCheckers';
import invoices from './pages/invoices';
import incidents from './pages/incidents';
import users from './pages/users';
import alerts from './pages/alerts';
import checkerJobs from './pages/checkerJobs';
import customRoutes from './customRoutes';
import { Layout } from './components/Layout';
import Menu from './Menu';
import seoCheckers from './pages/seoCheckers';

const App = () => {
  return (
    <Admin
      title="PageChecker Admin"
      customRoutes={customRoutes}
      menu={Menu}
      // layout={Layout}
      // dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      // theme={theme}
      // history={history}
    >
      {(permissions: any) => [
        <Resource
          options={{ label: 'Customers' }}
          name="customers"
          {...customers}
        />,
        <Resource
          options={{ label: 'Page Checkers' }}
          name="page-checkers"
          {...pageCheckers}
        />,
        <Resource
          options={{ label: 'Form Checkers' }}
          name="form-checkers"
          {...formCheckers}
        />,
        <Resource
          options={{ label: 'SEO Checkers' }}
          name="seo-keywords"
          {...seoCheckers}
        />,
        <Resource
          options={{ label: 'Checker Jobs' }}
          name="checker-jobs"
          {...checkerJobs}
        />,
        <Resource
          options={{ label: 'Incidents' }}
          name="incidents"
          {...incidents}
        />,
        <Resource
          options={{ label: 'Invoices' }}
          name="invoices"
          {...invoices}
        />,
        <Resource options={{ label: 'Alerts' }} name="alerts" {...alerts} />,
        <Resource options={{ label: 'Plans' }} name="plans" {...plans} />,
        <Resource options={{ label: 'Users' }} name="users" {...users} />,
        <Resource name="countries" />,
        <Resource name="seo-rank-checker" />,
        <Resource name="pages" />,
      ]}
    </Admin>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
