import React from 'react';
import {
  createStyles,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import JSONPretty from 'react-json-pretty';
import { omit } from 'lodash';

const styles = (theme: any) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  classes:any;
  onClose: () => void;
}
const classes={
  root:'',
}
const DialogTitle = ((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = (MuiDialogContent);

const TestFormDialog = ({ open, handleClose, testResponse }: any) => {
  const checkerLog = testResponse?.log;
  const screenshot = checkerLog?.screenshot;

  return (
    <Dialog
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}>
      <DialogTitle classes={classes} id="test-result-dialog" onClose={handleClose}>
        Test Result
      </DialogTitle>
      <DialogContent dividers>
        <div
          style={{
            textAlign: 'center',
            border: '3px solid #ccc',
            background: '#eee',
            marginBottom: '1rem',
          }}>
          <Typography>
            <h2>Screenshot</h2>
          </Typography>
          <img
            alt="form screenshot"
            style={{ maxWidth: '100%' }}
            src={`data:image/jpeg;base64,${screenshot}`}
          />
        </div>
        <div
          style={{
            textAlign: 'center',
            border: '3px solid #ccc',
            background: '#eee',
          }}>
          <JSONPretty
            style={{
              textAlign: 'left',
              padding: '1rem',
            }}
            data={omit(checkerLog, 'screenshot')}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestFormDialog;
