import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Labeled,
  ReferenceField,
  FunctionField,
  Datagrid,
} from 'react-admin';
import StatusField from '../../components/StatusField';

const AlertShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Customer"
        source="customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}>
          <FunctionField
            render={(record: any) =>
              `${record.firstName ?? ''} ${record.lastName ?? ''}`
            }
          />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        label="Incident ID"
        source="incidentId"
        reference="incidents"
        link={(record, reference) => `/${reference}/${record.id}/show`}>
        <TextField source="id" />
      </ReferenceField>
      <TextField label="Type" source="type" emptyText="-" />
      <TextField label="Medium" source="medium" emptyText="-" />
      <TextField label="Recipient" source="recipient" emptyText="-" />
      <DateField label="Created At" source="createdAt" showTime />
      <Labeled label="Status">
        <StatusField label="status" source="status" />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);

export default AlertShow;
