import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  SelectInput,
  TextInput,
  ReferenceField,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { checkerJobsStatus as status, checkerJobsType } from './const';

const CheckerJobFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput label="Status" source="status" choices={status} resettable />
    <SelectInput
      label="Checker Type"
      source="checkerType"
      choices={checkerJobsType}
      resettable
    />
    <TextInput label="Checker ID" source="checkerId" resettable />
    <TextInput label="Incident ID" source="incidentId" resettable />
  </Filter>
);

const CheckerJobList = (props: any) => (
  <List
    title="Checker Jobs"
    exporter={false}
    filters={<CheckerJobFilter />}
    bulkActionButtons={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Checker Jobs</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <TextField source="checkerType" />
      <TextField source="checkerId" />
      <ReferenceField
        label="Incident ID"
        source="incidentId"
        reference="incidents"
        link={(record, reference) => `/${reference}/${record.incidentId}/show`}
        emptyText="-">
        <TextField source="id" />
      </ReferenceField>
      <TextField label="Created At" source="createdAt" emptyText="-" />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default CheckerJobList;
