import { fetchUtils } from 'react-admin';

export const httpClient = fetchUtils.fetchJson;

export const getUser = () => {
  return {
    user: {
      authenticated: true,
      token: `Bearer ${localStorage.getItem('token')}`,
    },
  };
};
