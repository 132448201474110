export const status = [
  { id: 'trial', name: 'TRIAL' },
  { id: 'paying', name: 'PAYING' },
  { id: 'expired', name: 'EXPIRED' },
  { id: 'suspended', name: 'SUSPENDED' },
  { id: 'canceled', name: 'CANCELED' },
];

export const isWeekly = [
  { id: true, name: 'Active' },
  { id: false, name: 'Inactive' },
];

export const plan = [
  { id: 1, name: 'Premium' },
  { id: 2, name: 'Standart' },
  { id: 3, name: 'Starter' },
];
