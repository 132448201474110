import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ReferenceField,
  UrlField,
  Datagrid,
  Labeled,
  FunctionField,
} from 'react-admin';
import StatusField from '../../components/StatusField';

const InvoiceShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Customer"
        source="customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}>
          <FunctionField
            render={(record: any) =>
              `${record.firstName ?? ''} ${record.lastName ?? ''}`
            }
          />
        </ReferenceField>
      </ReferenceField>
      <TextField label="Amount" source="amount" />
      <TextField label="Currency" source="currency" />
      <DateField
        label="Subscription Start Date"
        source="subscriptionStartFrom"
      />
      <DateField
        label="Subscription End Date"
        source="subscriptionActiveUntil"
      />
      <UrlField label="PDF File" source="invoiceUrl" />
      <TextField label="Stripe Invoice ID" source="stripeInvoiceId" />
      <Labeled label="Status">
        <StatusField label="status" source="status" />
      </Labeled>
    </SimpleShowLayout>
  </Show>
);

export default InvoiceShow;
