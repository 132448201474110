import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  FunctionField,
  AutocompleteInput,
  TextInput,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { status } from './const';
import { User } from '../../types';
import { get } from 'lodash';

const CustomerFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label="Plan" source="planId" reference="plans">
      <SelectInput optionText="planName" resettable />
    </ReferenceInput>
    <ReferenceInput
      label="User Email"
      source="adminUserId"
      reference="users"
      filterToQuery={searchText => ({ email: searchText })}>
      <AutocompleteInput optionText="email" resettable />
    </ReferenceInput>
    <SelectInput label="Status" source="status" choices={status} resettable />
  </Filter>
);

const CustomerList = (props: any) => (
  <List
    exporter={false}
    filters={<CustomerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Customers</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="User Email"
        source="emailuser"
        reference="users"
        link=""
        emptyText="-">
        <FunctionField render={(record: any) => get(record, 'email')} />
      </ReferenceField>

      <ReferenceField
        label="Plan"
        source="planId"
        reference="plans"
        link={(record, reference) => `/${reference}/${record.id}/show`}>
        <TextField source="planName" emptyText="-" />
      </ReferenceField>
      <DateField
        label="Subscription Active Until"
        source="subscriptionActiveUntil"
        emptyText="-"
      />
      <DateField label="Registered At" source="createdAt" showTime />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

export default CustomerList;
