import { linkToRecord } from 'react-admin';
import { Link } from 'react-router-dom';

const LinkField = (props: any) => {
  const { record, resource } = props;
  const linkToUser = linkToRecord('/' + resource, record.id, 'show');

  return <Link to={linkToUser}>{props.label ?? 'Detail'}</Link>;
};

export default LinkField;
