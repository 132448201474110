import React from 'react';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  UrlField,
} from 'react-admin';
import TestFormButton from '../../components/TestFormButton';

const FormCheckerEdit: React.FC<any> = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <p>
          Please use the "Headless Recorder" plugin to generate Puppeteer
          Script. The plugin is available{' '}
          <a
            href="https://chrome.google.com/webstore/detail/headless-recorder/djeegiggegleadkkbgopoonhjimgehda?hl=en"
            target="_blank">
            here
          </a>
          .
        </p>
        <UrlField label="URL" source="url" target="_blank" />
        <TextField source="emailCatcher" label="Email Catcher String" />
        <TextField source="scriptUpdatedAt" />
        <TextInput source="puppeteerScript" multiline fullWidth />
        <TestFormButton />
      </SimpleForm>
    </Edit>
  );
};

export default FormCheckerEdit;
