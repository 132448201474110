import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Title,
  TextInput,
  SimpleForm,
  Toolbar,
  Button,
  ReferenceInput,
  SelectInput,
  useDataProvider,
  fetchUtils,
} from 'react-admin';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useFormState } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { getUser, httpClient } from '../../utils/tools';
import JSONPretty from 'react-json-pretty';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const TestDialog = ({ open, handleClose, testResponse }: any) => {
  const {
    url,
    countryCode,
    keyword,
    rank,
    page,
    logs,
    screenshot,
    options,
  } = testResponse;
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}>
      <DialogTitle>
        {handleClose ? (
          <IconButton
            style={{ marginLeft: 'auto', display: 'flex' }}
            aria-label="close"
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex' }}>
          <div style={{ marginBottom: 20 }}>
            <h4 style={{ marginBottom: 10 }}>Search Config</h4>
            <div
              style={{
                textAlign: 'center',
                border: '3px solid #ccc',
                background: '#eee',
                marginBottom: 20,
              }}>
              <JSONPretty
                style={{
                  textAlign: 'left',
                  padding: '1rem',
                }}
                data={options}
              />
            </div>
          </div>
          <div style={{ marginLeft: 30 }}>
            <h4 style={{ marginBottom: 0 }}>Tested Page</h4>
            <div>URL: {url}</div>
            <div>keyword: {keyword}</div>
            <div>country: {countryCode}</div>
            <h4 style={{ marginBottom: 0 }}>Result</h4>
            <div>
              Rank:{' '}
              {rank > 0
                ? rank
                : `Not found, your page is not found when search with keyword "${keyword}"`}
            </div>
            <div>Google Page: {page}</div>
          </div>
        </div>
        {rank > 0 && (
          <>
            <h4 style={{ marginBottom: 10 }}>Screenshot</h4>
            <img
              alt="screenshot"
              style={{
                maxWidth: '100%',
                border: '3px solid #ccc',
                marginBottom: 20,
              }}
              src={`data:image/jpeg;base64,${screenshot}`}
            />
          </>
        )}
        <h4 style={{ marginBottom: 10 }}>Log</h4>
        <div
          style={{
            textAlign: 'center',
            border: '3px solid #ccc',
            background: '#eee',
            marginBottom: 20,
          }}>
          <JSONPretty
            style={{
              textAlign: 'left',
              padding: '1rem',
            }}
            data={logs}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const TestToolbar = () => {
  const { values } = useFormState();
  const dataProvider = useDataProvider();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenResult, setOpenResult] = useState(false);
  const [response, setResponse] = useState<any>({
    url: '',
    keyword: '',
    rank: 0,
    page: 0,
  });

  const handleTest = async () => {
    setIsLoading(true);
    try {
      const { url, keyword, countryCode } = values;

      const res = await httpClient(`${apiUrl}/seo-rank-checker`, {
        method: 'POST',
        body: JSON.stringify({
          url,
          keyword,
          countryCode,
          options: {
            maxPageToSearch: 2,
          },
        }),
        ...getUser(),
      });

      const { rank, page, logs, output, options = null } = res.json;
      const { screenshot } = output[page - 1];

      setResponse({
        url,
        countryCode,
        keyword,
        rank,
        page,
        logs,
        screenshot,
        options,
      });

      setIsLoading(false);
      setOpenResult(true);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={isLoading || !values.url}
        color="primary"
        variant="contained"
        onClick={handleTest}
        label="Test SEO Checker"
        startIcon={
          !isLoading ? <FindInPageIcon /> : <CircularProgress size={20} />
        }
      />
      <TestDialog
        open={isOpenResult && response}
        handleClose={() => setOpenResult(false)}
        testResponse={response}
      />
    </>
  );
};

const SEOTest = () => {
  return (
    <Card>
      <Title title="Test SEO Keyword Checker" />
      <CardContent>
        <h3>Test the SEO monitoring feature</h3>
        <SimpleForm toolbar={<TestToolbar />}>
          <TextInput label="URL" source="url" />
          <TextInput label="Keyword" source="keyword" />
          <ReferenceInput
            label="Country"
            source="countryCode"
            reference="countries">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </CardContent>
    </Card>
  );
};

export default SEOTest;
