import React from 'react';
import Chip from '@material-ui/core/Chip';
import { get } from 'lodash';
import { status as CustomerStatus } from '../../pages/customers/const';
import { status as InvoiceStatus } from '../../pages/invoices/const';

interface IStatusField {
  label?: string;
  record?: any;
  source: string;
}

const style = {
  display: 'block',
  marginBottom: '5px',
  color: '#757575',
};

export const translateStatus = (status: string): string => {
  return status.toUpperCase();
};

const StatusField = ({ label, record = {}, source }: IStatusField) => {
  const status = get(record, source);
  const translated = translateStatus(status);

  return (
    <div>
      <Chip label={translated} />
    </div>
  );
};

export default StatusField;
