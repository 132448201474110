import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, fetchStart, fetchEnd, fetchUtils } from 'react-admin';
import { useFormState } from 'react-final-form';
import React from 'react';
import { testFormError } from '../../pages/formCheckers/const';
import TestFormDialog from '../TestFormDialog';

const TestFormButton = ({ record }: any) => {
  const notify = useNotify();
  const { values } = useFormState(); // Get current value from the form state
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const httpClient = fetchUtils.fetchJson;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState({});

  const formData = {
    url: values.url,
    path: values.path,
    puppeteerScript: values.puppeteerScript,
    submitSuccessString: values.submitSuccessString,
    expectedStringCount: Number(values.expectedStringCount),
  };

  const testForm = async () => {
    setLoading(true);

    dispatch(fetchStart()); // start the global loading indicator

    try {
      // Call API
      const res = await httpClient(`${apiUrl}/form-checker-puppeteer`, {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      // Parse response body
      const responseBody = JSON.parse(res.body);

      // Show snackbar based on response
      if (responseBody.status === 'success') {
        setDialogOpen(true);
        setTestResponse(responseBody);
        notify('Success: Submit Form Success', 'info');
      } else {
        let message = 'Submit failed';

        switch (responseBody.log.errorType) {
          case testFormError.FORM_SUCCESS_TEXT_NOT_FOUND:
            message = 'Submit success text not found';
            break;
          case testFormError.SUCCESS_TEXT_NOT_CONFIGURED:
            message = 'Success string not configured';
            break;
          case testFormError.TIMEOUT_ERROR:
            message = 'Timeout Error';
            break;
          default:
            break;
        }

        setDialogOpen(true);
        setTestResponse(responseBody);
        notify(`Error: ${message}`, 'warning');
      }
    } catch (e) {
      notify('Error: Submit Form Failed. Server error', 'error');
    } finally {
      setLoading(false);
      dispatch(fetchEnd()); // stop the global loading indicator
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Button
        disabled={loading}
        variant="contained"
        color="secondary"
        onClick={testForm}>
        Test Form Checker
      </Button>
      <TestFormDialog
        open={dialogOpen}
        handleClose={closeDialog}
        testResponse={testResponse}
      />
    </>
  );
};

export default TestFormButton;
