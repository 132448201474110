import { FC } from 'react';
import DoneAllIcon from '@material-ui/icons/DoneAll';

interface IEmptyPlaceholder {
  total?: number;
  label?: string;
}

const EmptyPlaceholder: FC<IEmptyPlaceholder> = ({
  total = 1,
  label = 'Data is empty',
}) => {
  if (!total) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          flexDirection: 'column',
          color: '#ccc',
        }}>
        <DoneAllIcon style={{ fontSize: '10rem' }} />
        <h2>{label}</h2>
      </div>
    );
  } else {
    return null;
  }
};

export default EmptyPlaceholder;
