import * as React from 'react';
import { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { AppState } from './types';

const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('xs'),
  );

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  return (
    <div>
      {resources.map(resource => {
        if (resource.hasList) {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) || resource.name
              }
              leftIcon={createElement(resource.icon)}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          );
        }
      })}
      <MenuItemLink
        to="/seo-test"
        primaryText="SEO Test"
        leftIcon={<SearchIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
