import FormCheckerList from './FormCheckerList';
import FormCheckerShow from './FormCheckerShow';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FormCheckerEdit from './FormCheckerEdit';

export default {
  list: FormCheckerList,
  show: FormCheckerShow,
  icon: LibraryBooksIcon,
  edit: FormCheckerEdit,
};
