import {
  DateField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import StatusField from '../../components/StatusField';

const CustomerShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="ID" source="id" />
      <ReferenceField label="Customer" source="pageId" reference="pages">
        <ReferenceField source="customerId" reference="customers">
          <TextField source="mainUser.email" />
        </ReferenceField>
      </ReferenceField>
      <TextField label="Keyword" source="keyword" />
      <ReferenceField
        source="countryId"
        reference="countries"
        emptyText="-"
        link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Start Rank" source="rankStart" />
      <TextField label="Previous Rank" source="rankPrevious" />
      <TextField label="Current Rank" source="rankCurrent" />
      <TextField label="Google Page" source="googlePage" />
      <Labeled label="Status">
        <StatusField label="status" source="status" />
      </Labeled>
      <DateField label="Last Update At" source="updatedAt" showTime />
    </SimpleShowLayout>
  </Show>
);

export default CustomerShow;
