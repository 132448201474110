import {
  Show,
  TextField,
  DateField,
  Labeled,
  FunctionField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Datagrid,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import JSONPretty from 'react-json-pretty';
import { get } from 'lodash';
import LinkField from '../../components/LinkField';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';

const IncidentShow = (props: any) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Basic Info">
        <TextField label="ID" source="id" />
        <TextField source="checkerType" />
        <TextField source="checkerId" label="Checker ID" />
        <TextField label="Title" source="title" emptyText="-" />
        <TextField label="Description" source="description" emptyText="-" />
        <FunctionField
          label="Log"
          render={(record: any) => (
            <JSONPretty data={get(record, 'technicalData')} />
          )}
        />
        <DateField label="Created At" source="createdAt" showTime />
        <DateField label="Closed At" source="closedAt" showTime />
        <Labeled label="Status">
          <StatusField label="status" source="status" />
        </Labeled>
      </Tab>
      <Tab label="Checker Jobs">
        <ReferenceManyField
          addLabel={false}
          reference="checker-jobs"
          target="incidentId">
          <div style={{ display: 'flex' }}>
            <Datagrid style={{ width: '100%' }}>
              <TextField source="id" />
              <TextField source="checkerType" />
              <TextField source="createdAt" />
              <StatusField source="status" />
              <LinkField />
            </Datagrid>
          </div>
        </ReferenceManyField>
        <ReferenceManyField
          addLabel={false}
          reference="checker-jobs"
          target="incidentId">
          <EmptyPlaceholder />
        </ReferenceManyField>
      </Tab>
      <Tab label="Alerts">
        <ReferenceManyField
          addLabel={false}
          reference="alerts"
          target="incidentId">
          <div style={{ display: 'flex' }}>
            <Datagrid style={{ width: '100%' }}>
              <TextField source="id" />
              <TextField source="medium" />
              <TextField source="recipient" />
              <TextField source="createdAt" />
              <StatusField source="status" />
              <LinkField />
            </Datagrid>
          </div>
        </ReferenceManyField>
        <ReferenceManyField
          addLabel={false}
          reference="alerts"
          target="incidentId">
          <EmptyPlaceholder />
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default IncidentShow;
