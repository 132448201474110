import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  DateField,
  ReferenceField,
  UrlField,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import { status } from './const';
import { get } from 'lodash';

const FormCheckerFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" resettable />
    <TextInput label="Url" source="url" resettable />
    <SelectInput label="Status" source="status" choices={status} resettable />
  </Filter>
);

const FormCheckerList = (props: any) => (
  <List
    title="Form Checkers"
    exporter={false}
    filters={<FormCheckerFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Form Checkers</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Customer"
        source="page.customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.page.customerId}/show`}
        emptyText="-">
        <TextField source="mainUser.email" />
      </ReferenceField>
      <UrlField label="URL" source="url" />
      <TextField label="Name" source="name" />
      <DateField
        label="Last Checked At"
        source="lastCheckedAt"
        showTime
        emptyText="-"
      />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default FormCheckerList;
