import React from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  TextField,
  UrlField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  SimpleShowLayout,
} from 'react-admin';
import { Helmet } from 'react-helmet';
import StatusField from '../../components/StatusField';

const InvoiceFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      label="Customer Name"
      source="customerId"
      reference="customers">
      <ReferenceInput source="adminUserId" reference="users" link="">
        <AutocompleteInput
          optionText={(choice: any) =>
            `${choice.firstName ?? ''} ${choice.lastName ?? ''}`
          }
          resettable
        />
      </ReferenceInput>
    </ReferenceInput>
  </Filter>
);

const InvoiceList = (props: any) => (
  <List
    exporter={false}
    filters={<InvoiceFilter />}
    bulkActionButtons={false}
    {...props}>
    <Datagrid rowClick="show">
      <Helmet>
        <title>Invoices</title>
      </Helmet>
      <TextField label="ID" source="id" />
      <ReferenceField
        label="Customer"
        source="customerId"
        reference="customers"
        link={(record, reference) => `/${reference}/${record.id}/show`}
        emptyText="-">
        <ReferenceField
          source="adminUserId"
          reference="users"
          link={record => `/customers/${record.id}/show`}>
          <FunctionField
            render={(record: any) =>
              `${record.firstName ?? ''} ${record.lastName ?? ''}`
            }
          />
        </ReferenceField>
      </ReferenceField>
      <TextField label="Amount" source="amount" />
      <TextField label="Currency" source="currency" />
      <DateField
        label="Subscription Start Date"
        source="subscriptionStartFrom"
      />
      <DateField
        label="Subscription End Date"
        source="subscriptionActiveUntil"
      />
      <UrlField label="PDF File" source="invoiceUrl" />
      <TextField label="Stripe Invoice ID" source="stripeInvoiceId" />
      <StatusField label="Status" source="status" />
    </Datagrid>
  </List>
);

export default InvoiceList;
