import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Labeled,
  ReferenceField,
  FunctionField,
  Datagrid,
  TopToolbar,
  EditButton,
  TabbedShowLayout,
  Tab,
  useRecordContext,
  Button,
  useNotify,
} from 'react-admin';
import StatusField from '../../components/StatusField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import SendIcon from '@material-ui/icons/Send';
import { getUser, httpClient } from '../../utils/tools';
import { apiUrl } from '../../utils/const';

const QuotaField = ({
  source,
  used,
  label,
  ...props
}: {
  label: string;
  used: string;
  source: string;
  [x: string]: any;
}) => {
  const { record } = useRecordContext<any>({});
  return (
    <div {...props} style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
        component="span"
        variant="body2">
        {label}
      </Typography>
      <Typography component="span" variant="body2">
        {record[used] ?? 0}/{record && record[source]}
      </Typography>
    </div>
  );
};

const SendWeeklyDigestButton = ({record}: {record: any}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const notify = useNotify();

  const handleSend = async() => {
    setIsLoading(true);
    try{
      const res = await httpClient(`${apiUrl}/weekly-digests`, {
        method: 'POST',
        body: JSON.stringify({
          customerId: record.id,
        }),
        ...getUser(),
      });
      setIsLoading(false);
      notify('Email sent!');
    }catch(e){
      setIsLoading(false);
    }
  };

  return (
    <Button
      disabled={isLoading || !record["id"]}
      color="primary"
      variant="contained"
      style={{marginLeft: 20}}
      onClick={handleSend}
      label="Send Weekly Digest"
      startIcon={
        !isLoading ? <SendIcon /> : <CircularProgress size={20} />
      }
    />
  ); 
};

const CustomShowActions = ({ basePath, data, resource }: any) => {
  if(data==undefined){
    return(
      <></>
    )
  }else{ 
    return (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} />
      <SendWeeklyDigestButton record={data}/>
    </TopToolbar>
  )
} 

};


const CustomerShow = (props: any) =>{ 
return (
  <Show actions={<CustomShowActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Basic Info">
        <TextField label="ID" source="id" />
        <ReferenceField
          label="email"
          source="emailuser"
          reference="users"
          link=""
          emptyText="-">
          <FunctionField render={(record: any) => `${record.email ?? ''}`} />
        </ReferenceField>
        <Labeled label="Status">
          <StatusField label="status" source="status" />
        </Labeled>
        <DateField label="Registered At" source="createdAt" showTime />
        <DateField label="Last Update At" source="updatedAt" showTime />
      </Tab>
      <Tab label="Subscribtion Info">
        <ReferenceField
          label="Plan"
          source="planId"
          reference="plans"
          link={(record, reference) => `/${reference}/${record.id}/show`}
          emptyText="-">
          <TextField source="planName" emptyText="-" />
        </ReferenceField>
        <TextField label="Card Brand" source="cardBrand" emptyText="-" />
        <TextField label="Card Last Four" source="cardLastFour" emptyText="-" />
        <TextField label="Card Exp Month" source="cardExpMonth" emptyText="-" />
        <TextField label="Card Exp Year" source="cardExpYear" emptyText="-" />
        <DateField
          label="Subscription Active Until"
          source="subscriptionActiveUntil"
          emptyText="-"
        />
      </Tab>
      {/*<Tab label="Quota">
        <div style={{ marginBottom: 10 }}>
          <QuotaField
            used="usedPage"
            label="Page Used"
            source="maxPageChecker"
          />
          <div style={{ marginBottom: 10 }}></div>
          <QuotaField
            used="usedSms"
            label="SMS/WA Notification Used"
            source="maxSms"
          />
        </div>
      </Tab>*/}
    </TabbedShowLayout>
  </Show>
);
};

export default CustomerShow;
