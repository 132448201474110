export const status = [
  { id: 'stopped', name: 'STOPPED' },
  { id: 'running', name: 'RUNNING' },
  { id: 'scheduled', name: 'SCHEDULED' },
];

export const testFormError = {
  FORM_SUBMIT_FAILED: 'form_submit_failed',
  FORM_SUCCESS_TEXT_NOT_FOUND: 'form_success_text_not_found',
  SUCCESS_TEXT_NOT_CONFIGURED: 'success_string_not_configured',
  TIMEOUT_ERROR: 'timeout_error',
};
